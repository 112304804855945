import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';

class Standard {
    constructor(timeline, label, duration, panel, next, previous, maxRect, minRect) {
        const nextType = next ? $(next).data('reveal') : 'na';
        const animationType = panel.data('text-animation');
        this.showing = false;
        this.split = new SplitText(panel.find('[data-reveal-content]').find('p,h2').nodes, { type: animationType === 'letters' ? 'chars,words' : 'lines' });
        this.items = animationType === 'letters' ? this.split.chars : this.split.lines;

        if (animationType === 'letters') {
            const offset = Math.round(Viewport.width * 0.05);

            TweenMax.set(this.items, { y: offset, opacity: 0 });

            this.show = () => {
                this.showing = true;
                TweenMax.killTweensOf(this.items);
                TweenMax.staggerTo(this.items, 1, { y: 0, ease: Expo.easeOut }, 0.015);
                TweenMax.staggerTo(this.items, 0.3, { opacity: 1, ease: Sine.easeOut }, 0.015);
            };

            this.hide = modifier => {
                this.showing = false;
                TweenMax.killTweensOf(this.items);
                TweenMax.staggerTo(this.items, 0.5, { y: offset * modifier * -1, ease: Quad.easeIn }, 0.01);
                TweenMax.staggerTo(this.items, 0.2, { delay: 0.3, opacity: 0, ease: Sine.easeOut }, 0.01);
            };
        } else if (animationType === 'lines') {
            const offset = Math.round(Viewport.height * 0.075) * -1;

            TweenMax.set(this.items, { y: offset * -1, opacity: 0 });

            this.show = modifier => {
                this.showing = true;
                const targets = modifier === 1 ? [].concat(this.items).reverse() : this.items;
                TweenMax.killTweensOf(targets);
                TweenMax.staggerTo(targets, 1.25, { y: 0, ease: Expo.easeOut }, 0.055);
                TweenMax.staggerTo(targets, 0.35, { opacity: 1, ease: Sine.easeOut }, 0.055);
            };

            this.hide = modifier => {
                this.showing = false;
                const targets = modifier === 1 ? this.items : [].concat(this.items).reverse();
                TweenMax.killTweensOf(targets);
                TweenMax.staggerTo(targets, 0.5, { y: offset * modifier, ease: Expo.easeIn }, 0.045);
                TweenMax.staggerTo(targets, 0.25, { delay: 0.25, opacity: 0, ease: Sine.easeOut }, 0.045);
            };
        } else {
            const isRight = panel.find('[data-reveal-alignment]').hasClass('t-right@m');
            const offset = Math.round(Viewport.width * 0.025) * (isRight ? -1 : 1);

            TweenMax.set(this.items, { x: offset, opacity: 0 });

            this.show = () => {
                this.showing = true;
                TweenMax.killTweensOf(this.items);
                TweenMax.staggerTo(this.items, 1.25, { x: 0, ease: Expo.easeOut }, 0.075);
                TweenMax.staggerTo(this.items, 0.35, { opacity: 1, ease: Sine.easeOut }, 0.075);
            };

            this.hide = () => {
                this.showing = false;
                TweenMax.killTweensOf(this.items);
                TweenMax.staggerTo(this.items, 0.5, { x: offset, ease: Expo.easeIn }, 0.045);
                TweenMax.staggerTo(this.items, 0.25, { delay: 0.25, opacity: 0, ease: Sine.easeOut }, 0.045);
            };
        }
        timeline.add(label);

        if (next) {
            timeline.fromTo(panel.get(0), duration, { clip: maxRect }, {
                clip: minRect,
                ease: Quad.easeInOut
            }, label);
        } else {
            const footerHeight = $('.page-footer').height();
            timeline.fromTo(panel.get(0), footerHeight, { y: 0 }, {
                y: (footerHeight * -1) * 0.5,
                ease: Linear.easeNone
            }, label);
        }

        const start = Math.round(duration * 0.3);
        const end = Math.round(duration * 0.7);

        if (next) {
            timeline.add(() => {
                if (this.showing) {
                    this.hide(1);
                }
            }, `${label}+=${start + 1}`);
        }

        timeline.add(() => {
            if (!this.showing) {
                this.show(1);
            }
        }, `${label}+=${start - 1}`);

        if (nextType === 'standard') {
            timeline.add(() => {
                if (this.nextInstance && !this.nextInstance.showing) {
                    this.nextInstance.show(-1);
                }
            }, `${label}+=${end + 1}`);
            timeline.add(() => {
                if (this.nextInstance && this.nextInstance.showing) {
                    this.nextInstance.hide(-1);
                }
            }, `${label}+=${end - 1}`);
        }
    }

    destroy() {
        this.showing = false;
        if (this.items) {
            TweenMax.killTweensOf(this.items);
        }
        if (this.split) {
            this.split.revert();
        }
    }
}

export default Standard;
