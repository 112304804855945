import Viewport from '../../core/Viewport';
import $ from '../../core/Dom';

class Splash {
    constructor(timeline, label, duration, panel, next, previous, maxRect, minRect) {
        const content = panel.find('[data-reveal-content]').nodes;
        const nextType = next ? $(next).data('reveal') : 'na';

        timeline.add(label);
        timeline.fromTo(content, duration, {
            y: 0,
            scale: 1,
            autoAlpha: 1
        }, {
            y: Viewport.height * 0.5 * -1,
            autoAlpha: 0,
            scale: 0.8,
            ease: Sine.easeInOut
        }, label);
        timeline.fromTo(panel.nodes, duration, { clip: maxRect }, { clip: minRect, ease: Quad.easeInOut }, label);

        if (nextType === 'standard') {
            const end = Math.round(duration * 0.7);
            timeline.add(() => {
                if (this.nextInstance && !this.nextInstance.showing) {
                    this.nextInstance.show(-1);
                }
            }, `${label}+=${end + 1}`);
            timeline.add(() => {
                if (this.nextInstance && this.nextInstance.showing) {
                    this.nextInstance.hide(-1);
                }
            }, `${label}+=${end - 1}`);
        }
    }

    destroy() {

    }
}

export default Splash;
