import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import $ from '../core/Dom';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);

    const emitMenuOpen = e => {
        Dispatch.emit(Events.MENU_CLOSE, e.target.hash.substring(1));
        Dispatch.emit(Events.MENU_OPEN, e.target.hash.substring(1));
    };

    const onHamburgerClick = e => {
        e.preventDefault();
        emitMenuOpen(e);
    };

    const onTriggerClick = e => {
        e.preventDefault();
        emitMenuOpen(e);
    };

    const init = () => {
        dom.on('click', '[data-trigger]', onTriggerClick);
        dom.on('click', '[data-hamburger]', onHamburgerClick);
    };

    const destroy = () => {
        dom.off('click');
    };

    return {
        init,
        destroy
    };
};
