import $ from '../core/Dom';
import VideoController from '../lib/VideoController';

export default el => {
    const $el = $(el);
    const link = $el.find('[data-link]');
    const videoWrap = $el.find('[data-embed]');
    let controller;
    let videoInited = false;

    const onStateChange = status => {
        console.log(status);
        TweenLite.killTweensOf(link.get(0));

        if (status === controller.PLAY) {
            TweenLite.to(link.get(0), 0.5, { autoAlpha: 0 });
        } else if (status === controller.PAUSE) {
            TweenLite.to(link.get(0), 0.5, { delay: 0.5, autoAlpha: 1 });
        }
    };

    const onClick = e => {
        e.preventDefault();

        if (!videoInited) {
            videoInited = true;
            videoWrap.html(videoWrap.data('embed'));
            controller.init(videoWrap.find('iframe'), true);
            TweenLite.to(link.get(0), 0.5, { autoAlpha: 0 });
        } else {
            controller.play();
        }
    };

    const init = () => {
        controller = new VideoController(onStateChange);
        $el.on('click', '[data-link]', onClick);
    };

    const destroy = () => {
        $el.off('click', '[data-link]', onClick);
        controller.destroy();
    };

    return {
        init,
        destroy
    };
};
