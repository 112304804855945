import Viewport from '../core/Viewport';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const list = dom.find('[data-list]');
    const shader = dom.find('[data-shader]');
    const items = dom.find('[data-item]');
    const first = items.first();
    const expander = dom.find('[data-expand]');
    const bottomDot = dom.find('[data-bottom-dot]');
    let isOpen = false;
    let columns = 1;

    const redraw = () => {
        const numberOfColumns = Viewport.width > 750 ? 2 : 1;

        if (numberOfColumns !== columns) {
            columns = numberOfColumns;
            TweenLite.set(items.nodes, {
                position: 'relative', top: 'auto', left: 'auto', right: 'auto' 
            });
            TweenLite.set(list.nodes, { height: 'auto' });
        }

        if (columns > 1) {
            const marginBottom = parseInt(first.css('marginBottom'), 10);
            items.each((item, index) => {
                if (index + 1 > columns) {
                    const itemAbove = items.eq(index - columns);
                    const props = {
                        position: 'absolute',
                        top: Math.floor(itemAbove.position().top + itemAbove.height() + marginBottom),
                        left: 0
                    };
                    if (index % 2 !== 0) {
                        props.left = 'auto';
                        props.right = 0;
                    }
                    TweenLite.set(item, props);
                }
            });

            const lastRowOffsets = items.slice(columns * -1).map(node => {
                const item = $(node);
                return Math.floor(item.position().top + item.height());
            });

            list.height(Math.max.apply(null, lastRowOffsets));
        }
    };

    const onResize = () => {
        if (isOpen) {
            redraw();
        }
    };

    const open = e => {
        e.preventDefault();
        isOpen = true;

        const collapsedHeight = list.height();
        TweenLite.to(bottomDot.nodes, 0.3, { opacity: 1, ease: Quad.easeInOut });
        TweenLite.to(expander.nodes.concat(shader.nodes), 0.3, {
            autoAlpha: 0,
            ease: Quad.easeInOut,
            onComplete() {
                TweenLite.set(expander.nodes, { display: 'none' });
            }
        });

        items.css('display', 'block');

        redraw();

        TweenLite.set(list.nodes, { maxHeight: 'none', overflow: 'hidden' });

        const expandedHeight = list.height();

        TweenLite.fromTo(list.nodes, 1, { height: collapsedHeight }, { height: expandedHeight, ease: Cubic.easeInOut });

    };

    const init = () => {
        expander.on('click', open);
        Viewport.on('resize', onResize);
    };

    const destroy = () => {
        expander.off('click');
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
