import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import $ from '../core/Dom';
import * as Events from '../lib/events';

export default el => {
    const dom = $(el);
    const html = $('html');
    const scroller = dom.find('[data-scroller]');
    const logo = dom.find('[data-logo]');
    const heading = dom.find('[data-heading]');
    const trigger = dom.find('[data-trigger]');

    const contentDelay = parseFloat(dom.data('content-delay')) || 0;
    let contentShowed = false;

    const path = logo.find('.clip-path');

    const video = dom.find('[data-video]').get(0);
    const canPlayVideo = html.hasClass('inlinevideo');

    let delayId = 0;


    const showContents = () => {
        let delay = 0;
        contentShowed = true;

        TweenMax.set(logo.nodes, { opacity: 1 });

        // 2
        TweenMax.fromTo(path.nodes, 2, {
            scaleX: 0.5, scaleY: 0, transformOrigin: '59px 83px'
        }, {
            transformOrigin: '59px 83px', scaleY: 1.1, scaleX: 1.5, ease: Cubic.easeInOut, delay
        });

        // 1.6
        TweenMax.fromTo(path.nodes, 1.6, {
            rotation: '-45deg', transformOrigin: '59px 83px'
        }, {
            transformOrigin: '59px 83px', rotation: '45deg', ease: Circ.easeInOut, delay
        });

        delay += 1.5;

        TweenMax.fromTo(heading.nodes, 0.85, { opacity: 0 }, { opacity: 1, ease: Sine.easeOut, delay });
        TweenMax.fromTo(heading.nodes, 1.75, { y: Viewport.height * 0.02 }, {
            y: 0, ease: Expo.easeOut, clearProps: 'y', delay
        });

        delay += 0.15;

        TweenMax.fromTo(trigger.nodes, 0.85, { opacity: 0 }, { opacity: 1, ease: Sine.easeOut, delay });
        TweenMax.fromTo(trigger.nodes, 1.75, { y: Viewport.height * 0.02 }, {
            y: 0, ease: Expo.easeOut, clearProps: 'y', delay
        });

        delay += 1.5;

        TweenMax.to(scroller.nodes, 0.85, { opacity: 1, ease: Sine.easeOut, delay });
        TweenMax.fromTo(scroller.nodes, 1.75, { y: Viewport.height * 0.02 * -1 }, {
            y: 0, ease: Expo.easeOut, clearProps: 'y', delay
        });
    };

    const scrollToContent = e => {
        e.preventDefault();
        Viewport.scrollTo(scroller.attr('href'), {
            duration: 750
        });
    };

    const cancelDelay = () => {
        if (!contentShowed) {
            clearTimeout(delayId);
            clearInterval(delayId);
            Viewport.off('scroll', cancelDelay);
            Viewport.off('touchstart', cancelDelay);
            Viewport.off('mousedown', cancelDelay);
            showContents(0);
        }
    };

    const init = () => {
        scroller.on('click', scrollToContent);

        Viewport.once('scroll', cancelDelay);
        Viewport.once('touchstart', cancelDelay);
        Viewport.once('mousedown', cancelDelay);

        if (canPlayVideo && dom.data('has-video') === 'true') {
            delayId = setInterval(() => {
                if (video.currentTime >= contentDelay) {
                    clearInterval(delayId);
                    showContents();
                }
            }, 100);
        } else {
            delayId = setTimeout(() => {
                clearTimeout(delayId);
                showContents();
            }, contentDelay * 1000);
        }
    };

    const destroy = () => {
        clearTimeout(delayId);
        scroller.off('click');
        Viewport.off('scroll', cancelDelay);
        Viewport.off('touchstart', cancelDelay);
        Viewport.off('mousedown', cancelDelay);
    };

    return {
        init,
        destroy
    };
};
