import Viewport from '../core/Viewport';
import $ from '../core/Dom';

export default (el, props) => {
    const dom = $(el);
    const html = $('html');
    const image = dom.find('[data-image]');
    const video = dom.find('[data-video]');
    const videoCheckDelay = html.hasClass('safari') ? 250 : 50;
    let ratio = '';
    let videoUrl = '';
    let canPlayVideo = false;

    const getVideoUrl = currentRatio => (currentRatio === 'landscape' ? props.landscape || props.portrait : props.portrait || props.landscape);

    const loadVideo = () => {
        video.attr('src', videoUrl);
    };

    const resizeHandler = () => {
        const currentRatio = Viewport.width > Viewport.height ? 'landscape' : 'portrait';
        const currentUrl = getVideoUrl(currentRatio);

        if (currentRatio !== ratio && currentUrl !== videoUrl) {
            ratio = currentRatio;
            videoUrl = currentUrl;
            loadVideo();
        }
    };

    const init = () => {
        setTimeout(() => {
            canPlayVideo = html.hasClass('inlinevideo');
            if (canPlayVideo && (props.landscape || props.portrait)) {
                resizeHandler();
                image.css('display', 'none');
                Viewport.on('resize', resizeHandler);
            } else {
                image.addClass('lazyload');
            }
        }, videoCheckDelay);
    };

    const destroy = () => {
        Viewport.off('resize', resizeHandler);
    };

    return {
        init,
        destroy
    };
};
