import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import * as Events from '../lib/events';

let ACTIVE = null;

export default el => {
    const dom = $(el);
    const list = dom.find('[aria-labelledby]');
    const options = list.find('ul');
    const expander = dom.find('[aria-haspopup]');
    const trigger = dom.find('[data-trigger]');

    let isOpen = false;

    const close = () => {
        isOpen = false;
        update();
    };

    const keyup = e => {
        const key = e.keyCode || e.which;
        if (isOpen && key === 27) {
            close();
        }
    };

    const update = () => {
        expander.attr('aria-expanded', isOpen);
        list.attr('aria-expanded', isOpen);
        if (isOpen) {
            ACTIVE = el.id;
            Dispatch.emit(Events.DROPDOWN_OPEN);
            document.addEventListener('keyup', keyup);
            TweenLite.set(options.nodes, { display: 'block' });
            TweenLite.set(trigger.nodes, { display: 'none' });
        } else {
            document.removeEventListener('keyup', keyup);
            TweenLite.set(options.nodes, { display: 'none' });
            TweenLite.set(trigger.nodes, { display: 'block' });
        }
    };

    const onOpen = () => {
        console.log(ACTIVE, el.id);
        if (ACTIVE !== el.id && isOpen) {
            close();
        }
    };

    const toggle = e => {
        e.preventDefault();
        isOpen = !isOpen;
        update();
    };

    const init = () => {
        Dispatch.on(Events.DROPDOWN_OPEN, onOpen);
        expander.on('click', toggle);
        trigger.on('click', toggle);
    };

    const destroy = () => {
        expander.off('click', toggle);
        trigger.off('click', toggle);
        document.removeEventListener('keyup', keyup);
        Dispatch.off(Events.DROPDOWN_OPEN, onOpen);
    };

    return {
        init,
        destroy
    };
};
