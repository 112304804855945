import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';

class Slides {
    constructor(timeline, label, duration, panel, next, previous, maxRect, minRect) {
        const nextType = next ? $(next).data('reveal') : 'na';
        const slides = panel.find('[data-slides]');
        const numberOfSlides = panel.find('.block-slides__slide').length;
        const offset = duration / numberOfSlides;

        timeline.add(label);
        timeline.fromTo(slides.nodes, duration, { x: '100%' }, { x: `-${(numberOfSlides) * 100}%`, ease: Sine.easeInOut }, `${label}-=${offset}`);
        timeline.fromTo(panel.get(0), offset, { clip: maxRect }, { clip: minRect, ease: Quad.easeInOut }, `-=${offset}`);

        if (nextType === 'standard') {
            const end = Math.round(offset * 0.3);
            timeline.add(() => {
                if (this.nextInstance && !this.nextInstance.showing) {
                    this.nextInstance.show(-1);
                }
            }, `-=${end - 1}`);
            timeline.add(() => {
                if (this.nextInstance && this.nextInstance.showing) {
                    this.nextInstance.hide(-1);
                }
            }, `-=${end + 1}`);
        }
    }

    destroy() {

    }
}

export default Slides;
