import TimelineMax from 'gsap/TimelineMax';
import $ from '../core/Dom';
import Numbers from './panels/Numbers';
import Slides from './panels/Slides';
import Standard from './panels/Standard';
import Splash from './panels/Splash';
import Viewport from '../core/Viewport';

let timeline = false;
let instances = [];

const destroy = () => {
    console.log('Panels.destroy');
    if (timeline) {
        console.log('Timeline.destroy');
        timeline.getChildren(true, false, true, 0).forEach(tl => {
            tl.kill().clear(true);
        });
        timeline.kill().clear(true);
    }
    instances.forEach(instance => instance.destroy());
};

const create = (panels, numberOfScreens) => {
    console.log('Panels.create');
    const heightOfPanels = Viewport.height * numberOfScreens;
    timeline = new TimelineMax({ useFrames: true, paused: true });
    instances = [];

    instances = panels.map((el, index) => {
        const panel = $(el);
        const label = `panel${index}`;
        const next = panels.get(index + 1);
        const previous = panels.get(index - 1);
        const duration = Math.round(heightOfPanels * (parseInt(panel.data('duration'), 10) / numberOfScreens));
        const type = panel.data('reveal');
        const maxRect = `rect(0px,${Viewport.width}px,${Viewport.height}px,0px)`;
        const minRect = `rect(0px,${Viewport.width}px,0px,0px)`;

        const args = [timeline, label, duration, panel, next, previous, maxRect, minRect];

        switch (type) {
            case 'numbers':
                return new Numbers(...args);
            case 'slides':
            case 'slides2':
                return new Slides(...args);
            case 'standard':
            case 'standard2':
                return new Standard(...args);
            case 'splash':
                return new Splash(...args);
            default:
                return null;
        }
    });

    console.log(instances);

    instances.forEach((instance, index) => {
        instance.nextInstance = instances[index + 1];
    });

    return timeline;
};

export default {
    create,
    destroy
};
