import 'gsap';
import 'gsap/TimelineMax';

import './lib/SplitText';
import lazySizes from './lib/lazysizes';
import Viewport from './core/Viewport';
import Components from './core/Components';
import $ from './core/Dom';

/*
*   Bootstrap app
*
 */

const setLocaleUrls = () => {
    const localeUrls = $('html').data('locale-urls') || {};

    Object.keys(localeUrls).forEach(key => {
        $('[data-lang-switcher]').find(`[hreflang="${key}"]`).attr('href', localeUrls[key]);
    });
};

const init = () => {
    setLocaleUrls();
    const ua = navigator.userAgent;
    const isDesktopSafari = !!ua.match(/safari/i) && !ua.match(/iphone|ipad|ipod/i) && !ua.match(/chrome/i) && typeof document.body.style.webkitFilter !== 'undefined' && !window.chrome;
    if (isDesktopSafari) {
        $(document).addClass('safari');
    }
    Viewport.init();
    Viewport.initTabbing();
    Components.init();
    lazySizes.init();
};

require('doc-ready')(init);
