import $ from '../../core/Dom';
import Viewport from '../../core/Viewport';

class Numbers {
    constructor(timeline, label, duration, panel, next, previous, maxRect, minRect) {
        const content = panel.find('[data-reveal-content]').get(0);
        const nextType = next ? $(next).data('reveal') : 'na';

        timeline.add(label);

        this.childTimeline = new TimelineMax();
        this.childTimeline.fromTo(content, duration, { y: Viewport.height * 0.5 }, { y: 0, ease: Linear.easeNone });
        this.childTimeline.to(content, duration, { y: Viewport.height * 0.5 * -1, ease: Linear.easeNone });
        timeline.add(this.childTimeline, `${label}-=${duration}`);

        if (nextType === 'numbers') {
            timeline.fromTo(panel.get(0), duration, { autoAlpha: 1 }, { autoAlpha: 0, ease: Expo.easeInOut }, `-=${duration}`);
        } else {
            timeline.fromTo(panel.get(0), duration, { clip: maxRect }, { clip: minRect, ease: Quad.easeInOut }, `-=${duration}`);
        }

        if (nextType === 'standard') {
            const end = Math.round(duration * 0.7);
            timeline.add(() => {
                if (this.nextInstance && !this.nextInstance.showing) {
                    this.nextInstance.show(-1);
                }
            }, `${label}+=${end + 1}`);
            timeline.add(() => {
                if (this.nextInstance && this.nextInstance.showing) {
                    this.nextInstance.hide(-1);
                }
            }, `${label}+=${end - 1}`);
        }
    }

    destroy() {
        this.showing = false;
    }
}

export default Numbers;
