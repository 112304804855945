/* global TweenLite TimelineLite Sine Circ Expo:true  */

import Viewport from '../core/Viewport';
import Dispatch from '../core/Dispatch';
import $ from '../core/Dom';
import * as Events from '../lib/events';

export default el => {
    let isOpen = false;
    let activeElement = null;
    let openerColor = '#191919';

    const dom = $(el);
    const shader = dom.find('[data-shader]');
    const background = dom.find('[data-background]');
    const wrapper = dom.find('[data-wrapper]');
    const closeBtn = dom.find('[data-close]');
    const isLeft = dom.data('alignment') === 'left';
    const isLargeScreen = dom.data('large') === 'true';
    const opener = $(`[href="#${el.id}"]`);

    const keyup = e => {
        const key = e.keyCode || e.which;
        if (isOpen && key === 27) {
            Dispatch.emit(Events.MENU_CLOSE);
        }
    };

    const open = (key, id) => {
        console.log('her');
        if (id === el.id && !isOpen) {
            isOpen = true;
            openerColor = opener.last().css('color');

            TweenLite.set(background.nodes, { x: isLeft ? '-100%' : '100%' });
            TweenLite.set(shader.nodes, { opacity: 0 });
            TweenLite.set(closeBtn.nodes, { opacity: 0 });
            TweenLite.set(wrapper.nodes, { opacity: 0, x: isLeft ? -30 : 30 });
            TweenLite.set(dom.nodes, { display: 'block' });

            TweenLite.to(shader.nodes, 0.3, { opacity: 0.5, ease: Circ.easeOut });
            TweenLite.to(background.nodes, 0.3, { x: '0%', ease: Circ.easeOut });
            TweenLite.to(opener.nodes, 0.3, { color: '#fff', ease: Circ.easeOut });
            TweenLite.to(closeBtn.nodes, 0.3, { delay: 0.2, opacity: 1, ease: Circ.easeOut });

            TweenLite.to(wrapper.nodes, 1.5, { delay: 0.15, x: 0, ease: Expo.easeOut });
            TweenLite.to(wrapper.nodes, 0.5, { delay: 0.15, opacity: 1, ease: Sine.easeInOut });

            activeElement = document.activeElement;
            Viewport.lockTabbing(el, closeBtn);
            document.addEventListener('keyup', keyup);
        }
    };

    const close = (key, id) => {
        if (id !== el.id && isOpen) {
            isOpen = false;
            TweenLite.to(closeBtn.nodes, 0.1, { opacity: 0, ease: Circ.easeOut });
            TweenLite.to(wrapper.nodes, 0.1, { opacity: 0, ease: Circ.easeOut });
            TweenLite.to(shader.nodes, 0.25, { opacity: 0, ease: Circ.easeOut });
            TweenLite.to(opener.nodes, 0.3, { color: openerColor, ease: Circ.easeOut });
            TweenLite.to(background.nodes, 0.25, {
                x: isLeft ? '-100%' : '100%',
                ease: Circ.easeOut,
                onComplete() {
                    dom.attr('style', null);
                } 
            });

            Viewport.releaseTabbing(activeElement);
            document.removeEventListener('keyup', keyup);
        }
    };

    const forceClose = () => {
        isOpen = false;
        dom.attr('style', null);
        opener.attr('style', null);
        Viewport.releaseTabbing(activeElement);
        document.removeEventListener('keyup', keyup);
    };

    const init = () => {
        Dispatch.on(Events.MENU_OPEN, open);
        Dispatch.on(Events.MENU_CLOSE, close);
        shader.on('click', e => {
            Dispatch.emit(Events.MENU_CLOSE);
        });
        closeBtn.on('click', e => {
            e.preventDefault();
            Dispatch.emit(Events.MENU_CLOSE);
        });
        Viewport.on('breakpoint', e => {
            if (isOpen) {
                if (isLargeScreen && e.detail.old.name === 'm' && e.detail.current.size < e.detail.old.size) {
                    forceClose();
                } else if (!isLargeScreen && e.detail.current.name === 'm' && e.detail.current.size > e.detail.old.size) {
                    forceClose();
                }
            }
        });
    };

    const destroy = () => {
        Viewport.off('breakpoint');
        Dispatch.off(Events.MENU_OPEN, open);
        Dispatch.off(Events.MENU_CLOSE, close);
        closeBtn.off('click');
        shader.off('click');
    };

    return {
        init,
        destroy
    };
};
