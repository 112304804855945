/*
 *   Lazysizes config
 *
 */
window.lazySizesConfig = {
    init: false
};

/* global LEGACY_BUILD */
/* eslint-disable global-require */
if (LEGACY_BUILD) {
    require('lazysizes/plugins/respimg/ls.respimg');
    require('lazysizes/plugins/bgset/ls.bgset');
}

/* eslint-enable global-require */
const lazySizes = require('lazysizes');

export default {
    init: () => lazySizes.init()
};
