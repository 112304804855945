import Viewport from '../core/Viewport';
import $ from '../core/Dom';
import Panels from './Panels';

export default el => {
    const dom = $(el);
    const panels = dom.find('[data-reveal]');
    const numberOfScreens = parseInt(dom.data('screens'), 10);
    let timeline = false;

    const getScrollTop = () => {
        const max = document.body.scrollHeight - Viewport.height;
        return Math.min(Math.max(Viewport.scrollTop, 0), max);
    };

    const onResize = () => {
        Panels.destroy();
        timeline = Panels.create(panels, numberOfScreens);
        timeline.time(getScrollTop());
    };

    const onScroll = () => {
        timeline.time(getScrollTop());
    };

    const go = () => {
        onResize();
        Viewport.on('scroll', onScroll);
        Viewport.on('resize', onResize);
    };
    
    const init = () => {
        if (document.fonts) {
            document.fonts.ready.then(() => {
                go();
            });
        } else {
            setTimeout(() => {
                go();
            }, 500);
        }
    };

    const destroy = () => {
        Panels.destroy();
        Viewport.off('scroll', onScroll);
        Viewport.off('resize', onResize);
    };

    return {
        init,
        destroy
    };
};
